import React from "react";

const OutcomeMessage = ({
  resultMessage,
  closeButtonHandler,
  OutcomeMessageHeading,
}) => {
  return (
    <div>
      <div className="overlay">
        <div className="outcomeMessage-container">
          <button className="outcome-close-button" onClick={closeButtonHandler}>
            X
          </button>
          {OutcomeMessageHeading.length > 0 ? (
            <div className="outcome-message">
              <h1>{OutcomeMessageHeading}</h1>
              <p>{resultMessage}</p>
            </div>
          ) : (
            <div className="loading-container">
              <div className="loading-circle"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OutcomeMessage;
