import React, { useState, useEffect } from "react";
import Navbar from "../NavBar/NavBar";
import axios from "axios";
import "./Cards.css";
import AllCards from "./AllCards";
import CurrentCard from "./CurrentCard";
import ShowMyListings from "./MyListings";
import { useAuth } from "../../AuthContext";

const Market = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [cardSet, setCardsSet] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);
  const [currentCardImagePath, setCurrentCardImagePath] = useState("");
  const [priceSymbol, setPriceSymbol] = useState("SWAP.HIVE");
  const [showMyListings, setShowMyListings] = useState(false);
  const [myListings, setMyListings] = useState();
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async (offset) => {
      const apis = [
        "https://ha.herpc.dtools.dev/contracts",
        "https://api.hive-engine.com/rpc/contracts",
        "https://herpc.dtools.dev/contracts",
        "https://engine.rishipanthee.com/contracts",
      ];
      const randomIndex = Math.floor(Math.random() * apis.length);
      const randomApi = apis[randomIndex];
      const params = {
        contract: "nftmarket",
        table: "PIRATESAGAsellBook",
        limit: 1000,
        offset: offset,
        indexes: [],
        query: { priceSymbol: priceSymbol },
      };
      const j = {
        jsonrpc: "2.0",
        id: 1,
        method: "find",
        params: params,
      };

      try {
        const response = await axios.post(randomApi, j);
        const data = response.data;

        if (data.result.length === 1000) {
          data.result = data.result.concat(await fetchData(offset + 1000));
        }

        const marketCards = response.data.result.filter((card) => {
          return card.account !== user;
        });

        const myListingCards = response.data.result.filter((card) => {
          return card.account === user;
        });

        setMyListings(myListingCards);

        setCards(marketCards);
        setLoading(false);

        const cardCounts = response.data.result.reduce((counts, card) => {
          const cardName = card.grouping.name;
          const price = card.price;
          const rarity = JSON.parse(card.grouping.nft).rarity;
          const key = `${rarity}_${cardName}_${price}`;
          counts[key] = (counts[key] || 0) + 1;
          return counts;
        }, {});
        const cardSetArray = Object.entries(cardCounts).map(([key, count]) => {
          const [rarity, name, price] = key.split("_");
          return { rarity, name, count, price };
        });
        setCardsSet(cardSetArray);

        return data.result;
      } catch (error) {
        // setError(error);
        setLoading(false);
        return [];
      }
    };
    fetchData(0);
  }, [priceSymbol, user]);

  const clickHandler = (cardName, imagePath) => {
    setCurrentCard(cardName);
    setCurrentCardImagePath(imagePath);
  };

  const currentCardClose = () => {
    setCurrentCard(null);
  };

  const handlePriceSymbolChange = (newPriceSymbol) => {
    setPriceSymbol(newPriceSymbol);
  };

  const handleShowListings = () => {
    setShowMyListings(true);
  };

  const closeMyListings = () => {
    setShowMyListings(false);
  };

  return (
    <div>
      <Navbar />
      <br />
      {loading ? (
        <p>Loading... Please Wait!</p>
      ) : currentCard === null ? (
        showMyListings ? (
          <ShowMyListings
            closeMyListings={closeMyListings}
            myListings={myListings}
          />
        ) : (
          <div>
            <button onClick={handleShowListings} className="showmylistings">
              Show My Listings
            </button>
            <AllCards
              cardSet={cardSet}
              clickHandler={clickHandler}
              handlePriceSymbolChange={handlePriceSymbolChange}
            />
          </div>
        )
      ) : (
        <CurrentCard
          allData={cards}
          currentCard={currentCard}
          currentImagePath={currentCardImagePath}
          closeCard={currentCardClose}
          priceSymbol={priceSymbol}
        />
      )}
    </div>
  );
};

export default Market;
