import React from "react";

const AllCards = ({ cardSet, clickHandler, handlePriceSymbolChange }) => {
  return (
    <div>
      <div className="priceSymbol">
        <label className="radioButton">
          <input
            onChange={() => handlePriceSymbolChange("SWAP.HIVE")}
            type="radio"
            name="token"
            value="SWAP.HIVE"
            defaultChecked
          />
          <span>SWAP.HIVE</span>
        </label>
        <label className="radioButton">
          <input
            onChange={() => handlePriceSymbolChange("LOOT")}
            type="radio"
            name="token"
            value="LOOT"
          />
          <span>LOOT</span>
        </label>
      </div>

      <div className="image-set">
        {cardSet.map((card, index) => {
          const imagePath = `/CardImages/${card.rarity}/${card.name
            .split(" ")
            .join("_")}.png`;
          return (
            <div className="images-container" key={index}>
              <img
                onClick={() => clickHandler(card.name, imagePath)}
                className="image-item"
                src={imagePath}
                alt={card.name}
              />
              <p className="cards-info">
                {card.name} : Price: {Math.round(card.price * 100) / 100}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllCards;
