import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Register.css"; // Separate CSS file for styling
import { useAuth } from "../../AuthContext";
import OutcomeMessage from "../OutcomeMessage/OutcomeMessage";
import axios from "axios";

const Register = () => {
  const [discordId, setDiscordId] = useState(null);
  const [hiveUsername, setHiveUsername] = useState("");
  const [role, setRole] = useState("");
  const [step, setStep] = useState(1);
  const [confirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { apiUrl } = useAuth();

  const startDiscordLogin = () => {
    window.location.href = `${apiUrl}/discord/login/`; // Backend URL for Discord OAuth
  };

  const roleMap = {
    1: "Navigator",
    2: "Fighter",
    3: "Healer",
    4: "Scavenger",
  };

  const handleUsernameSubmit = (e) => {
    e.preventDefault();

    if (discordId === null) {
      setErrorMessage("Please connect your discord ID first to play the game");
      setStep(1);
      return;
    }

    let keychain = window.hive_keychain;

    keychain.requestSignBuffer(hiveUsername, "login", "Posting", (response) => {
      if (response.success === true) {
        const userExists = axios.post(`${apiUrl}/check_user_details/`, {
          discord_Id: discordId,
          hive_user_name: hiveUsername,
        });
        if (userExists) {
          setErrorMessage(
            `The player with discord id: ${discordId} and hive user name ${hiveUsername} already exists! Please try to register with another hive user name and discord id. Or login with your credentials!`
          );
        } else {
          setStep(2);
        }
      }
    });
  };

  const handleRoleSelect = (roleChoice) => {
    setRole(roleMap[roleChoice]);
    setStep(3);
  };

  const handleConfirm = () => {
    setConfirmation(true);
    // Make API call here to register the user
    const keychain = window.hive_keychain;
    const payload = {
      contractName: "tokens",
      contractAction: "transfer",
      contractPayload: {
        symbol: "SWAP.HIVE",
        to: "deckhand",
        quantity: `5`,
        memo: "activate",
      },
    };

    keychain.requestCustomJson(
      hiveUsername,
      "ssc-mainnet-hive",
      "Active",
      JSON.stringify(payload),
      `Send 5 swap.hive to deckhand`,
      (response) => {
        console.log(response);
        if (response.success) {
          // setLoadTransactionImage(true);
          axios
            .post(`${apiUrl}/create_player/`, {
              discord_id: discordId,
              hive_user_name: hiveUsername,
              player_role: role,
              cooldown_period: "00:00:00",
            })
            .then((response) => {
              console.log(response);
              if (response.status === 200) {
                axios
                  .put(`${apiUrl}/activate/`, {
                    discord_id: discordId,
                    bonus: false,
                  })
                  .then((response) => {
                    if (response.status === 200) {
                      setErrorMessage(response.message);
                    } else {
                      setErrorMessage(response.error);
                    }
                  });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    );
  };

  const handleCancel = () => {
    setStep(1);
    setHiveUsername("");
    setRole("");
    setConfirmation(false);
  };

  React.useEffect(() => {
    // Check for Discord ID in URL
    const params = new URLSearchParams(window.location.search);
    const discordId = params.get("discord_id");
    if (discordId) {
      setDiscordId(discordId);
      // setStep(2); // Proceed to next step after Discord authentication
    }
  }, []);
  const handleOutcomeCancelButton = () => {
    setErrorMessage("");
  };

  return (
    <div>
      <div className="homepage-nav">
        <Link to="/login" className="nav-link">
          Login
        </Link>
      </div>
      <div className="register-container">
        {errorMessage !== "" ? (
          <OutcomeMessage
            OutcomeMessageHeading="Error"
            resultMessage={errorMessage}
            closeButtonHandler={handleOutcomeCancelButton}
          />
        ) : step === 1 ? (
          <div>
            <div className="discord-auth">
              <h1>Welcome, Pirate!</h1>
              <h2>Discord Linked</h2>
              <p>Discord ID: {discordId}</p>
              <p>Begin your registration by linking your Discord account.</p>
              {discordId === null ? (
                <button
                  onClick={startDiscordLogin}
                  className="discord-login-btn"
                >
                  Connect your Discord id
                </button>
              ) : (
                ""
              )}
            </div>
            <form onSubmit={handleUsernameSubmit} className="register-form">
              <h1>Register as a Pirate</h1>
              <label htmlFor="hiveUsername">Enter Hive Username:</label>
              <input
                type="text"
                id="hiveUsername"
                value={hiveUsername}
                onChange={(e) => setHiveUsername(e.target.value)}
                placeholder="Hive Username"
                required
              />
              <button type="submit">Next</button>
            </form>
          </div>
        ) : step === 2 ? (
          <div className="role-selection">
            <h1>Select Your Role</h1>
            <button onClick={() => handleRoleSelect("1")}>Navigator</button>
            <button onClick={() => handleRoleSelect("2")}>Fighter</button>
            <button onClick={() => handleRoleSelect("3")}>Healer</button>
            <button onClick={() => handleRoleSelect("4")}>Scavenger</button>
          </div>
        ) : step === 3 ? (
          <div className="confirmation">
            <h1>Confirm Registration</h1>
            <p>Hive Username: {hiveUsername}</p>
            <p>Role: {role}</p>
            <p>
              Now, to activate your account, you have to send 5 swap.hive to
              deckhand with a memo activate. This is to prevent bots and
              multiaccounts, as most of the commands are free to play. Click on
              confirm to send 5 swap.hive. After that, your registration is
              successful, and you can start playing on discord channel and on
              web app. You will receive 2500 Loot coins once your account is
              activated.
            </p>
            <button onClick={handleConfirm}>Confirm</button>
            <button onClick={handleCancel}>Cancel</button>
          </div>
        ) : confirmation ? (
          <div className="success-message">
            <h1>Registration Successful!</h1>
            <p>Welcome aboard, {hiveUsername}!</p>
            <p>Your adventure as a {role} begins now.</p>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Register;
