import React from "react";
import CardDetails from "./CardDetails/CardDetails";
import GameDetails from "./GameDetails/GameDetails";
import Navbar from "../NavBar/NavBar";
import { useAuth } from "../../AuthContext";
import "./HomePage.css";
import { Link } from "react-router-dom";

const HomePage = () => {
  const { isAuthenticated } = useAuth();
  return (
    <div className="homepage-container">
      {isAuthenticated ? (
        <Navbar />
      ) : (
        <div className="homepage-nav">
          <Link to="/register" className="nav-link">
            Register
          </Link>
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </div>
      )}
      <GameDetails />
      {Object.entries(CardDetails).map(([key, value]) => {
        return (
          <div className="category-container" key={key}>
            <h2 className="rarity-heading">{key}</h2>
            <div className="image-set">
              {value.map((e) => {
                const imagePath = `/CardImages/${key}/${e
                  .split(" ")
                  .join("_")}.png`;
                return (
                  <div className="image-item" key={e}>
                    <img src={imagePath} alt={e.split(" ").join("_")} />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HomePage;
