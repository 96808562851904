import React, { useState } from "react";
import SellCards from "./SellCards";
import { useAuth } from "../../AuthContext";

const CurrentCard = ({
  allData,
  currentCard,
  closeCard,
  currentImagePath,
  priceSymbol,
}) => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [sellPrompt, setSellPrompt] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const { user } = useAuth();

  const currentCardData = allData.filter(
    (card) => card.grouping.name === currentCard
  );

  const handleUnselectAll = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setSelectedCards([]);
  };
  const handleCardId = (cardId, cardPrice) => {
    if (selectedCards.includes(cardId)) {
      setSelectedCards(selectedCards.filter((id) => id !== cardId));
      setTotalPrice((prevPrice) => (prevPrice -= parseFloat(cardPrice)));
    } else {
      setSelectedCards([...selectedCards, cardId]);
      setTotalPrice((prevPrice) => (prevPrice += parseFloat(cardPrice)));
    }
  };

  const handleSale = () => {
    setSellPrompt(true);
  };

  const handleBuy = (selectedCards) => {
    const keychain = window.hive_keychain;
    // const stringifyCards = selectedCards.map((el) => JSON.stringify(el));
    const payload = {
      contractName: "nftmarket",
      contractAction: "buy",
      contractPayload: {
        symbol: "PIRATESAGA",
        nfts: selectedCards,
        marketAccount: "deckhand",
        expPrice: totalPrice,
        expPriceSymbol: priceSymbol,
      },
    };

    keychain.requestCustomJson(
      user,
      "ssc-mainnet-hive",
      "Active",
      JSON.stringify(payload),
      "Buy Cards",
      (response) => {
        // console.log(response);
      }
    );
  };

  const cancelSale = () => {
    setSellPrompt(false);
  };

  // const handleTransfer = () => {
  //   console.log("Transfer");
  // };

  console.log("here are the selected cards ", selectedCards);
  return (
    <div>
      <button onClick={closeCard}>Back</button>
      <button onClick={handleUnselectAll}>Unselect All</button>
      {sellPrompt ? (
        <SellCards
          handleSale={handleSale}
          cancelSale={cancelSale}
          selectedCards={selectedCards}
        />
      ) : (
        ""
      )}
      {selectedCards.length > 0 ? (
        <div>
          <button onClick={() => handleBuy(selectedCards)}>Buy</button>
          {/* <button onClick={handleTransfer} className="transferButton">
            Transfer
          </button> */}
        </div>
      ) : (
        ""
      )}
      <div className="currentCardContainer">
        <img className="image-item" src={currentImagePath} alt={currentCard} />
        {currentCardData.map((card, index) => (
          <div className="currentCardIds" key={index}>
            <input
              onClick={() => handleCardId(card.nftId, card.price)}
              type="checkbox"
              id="myCheckBox"
              name="myCheckbox"
            />
            <label htmlFor="myCheckBox">{card.nftId}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurrentCard;
