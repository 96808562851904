import React, { useState } from "react";
import { useAuth } from "../../AuthContext";

const ShowMyListings = ({ myListings, closeMyListings }) => {
  const [currentListing, setCurrentListing] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const { user } = useAuth();

  const handlePriceSymbolChange = (priceSymbol) => {
    const currentListingCards = myListings.filter((card) => {
      return card.priceSymbol === priceSymbol;
    });
    setCurrentListing(currentListingCards);
    setSelectedCards([]);
  };

  const handleCardSelection = (cardId) => {
    setSelectedCards((prevSelectedCards) =>
      prevSelectedCards.includes(cardId)
        ? prevSelectedCards.filter((id) => id !== cardId)
        : [...prevSelectedCards, cardId]
    );
  };

  const handleCardRemoval = () => {
    const keychain = window.hive_keychain;
    // const stringifyCards = selectedCards.map((el) => JSON.stringify(el));
    const payload = {
      contractName: "nftmarket",
      contractAction: "cancel",
      contractPayload: {
        symbol: "PIRATESAGA",
        nfts: selectedCards,
      },
    };

    keychain.requestCustomJson(
      user,
      "ssc-mainnet-hive",
      "Active",
      JSON.stringify(payload),
      "Cancel Cards",
      (response) => {}
    );
  };
  return (
    <div>
      <button onClick={closeMyListings}>Close</button>
      {selectedCards.length > 0 ? (
        <button onClick={handleCardRemoval} className="cancelButton">
          Remove From Market
        </button>
      ) : (
        ""
      )}
      <div>
        <div className="priceSymbol">
          <label className="radioButton">
            <input
              onChange={() => handlePriceSymbolChange("SWAP.HIVE")}
              type="radio"
              name="token"
              value="SWAP.HIVE"
              defaultChecked
            />
            <span>SWAP.HIVE</span>
          </label>
          <label className="radioButton">
            <input
              onChange={() => handlePriceSymbolChange("LOOT")}
              type="radio"
              name="token"
              value="LOOT"
            />
            <span>LOOT</span>
          </label>
        </div>
        {currentListing.map((card) => {
          return (
            <label className="checkboxLabel" key={card._id}>
              <input
                type="checkbox"
                checked={selectedCards.includes(card.nftId)}
                onChange={() => handleCardSelection(card.nftId)}
              />
              <span>{`${card._id} ${card.grouping.name} ${card.price} ${card.priceSymbol}`}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default ShowMyListings;
