import React, { useState, useEffect } from "react";

import { useAuth } from "../../AuthContext";
import axios from "axios";
import Cookies from "js-cookie";

const BuyChests = () => {
  const [chestsToBuy, setChestsToBuy] = useState(1);
  const [userBalance, setUserBalance] = useState({});
  const [chestDetails, setChestDetails] = useState(0);
  const [currency, setCurrentCurrency] = useState("SWAP.HIVE");
  const [totalAmount, setTotalAmount] = useState(1);
  const [lowBalance, setLowBalance] = useState(false);
  const [confirmTransaction, setAwaitTransaction] = useState(false);
  const [confirmTransactionText, setConfirmTransactionText] = useState(false);
  // const [loadTransactionImage, setLoadTransactionImage] = useState(false);
  const { user, userData, apiUrl, updateData, setUpdateData } = useAuth();
  const handleChestsChange = (event) => {
    const chestAmountInput = event.target.value;
    setChestsToBuy(event.target.value);
    let totalPrice;
    if (currency === "LOOT") {
      totalPrice = chestAmountInput * 1000;
    } else {
      totalPrice = (chestAmountInput * chestDetails).toFixed(2);
    }

    if (parseFloat(userBalance[currency]) < totalPrice) {
      setLowBalance(true);
    } else {
      setLowBalance(false);
    }

    setTotalAmount(totalPrice);
  };

  const handleCurrencyChange = (e) => {
    setCurrentCurrency(e.target.value);
  };

  useEffect(() => {
    let totalPrice;
    if (currency === "SWAP.HIVE") {
      totalPrice = (chestDetails * chestsToBuy).toFixed(2);
    } else {
      totalPrice = chestsToBuy * 1000;
    }
    if (parseFloat(userBalance[currency]) < totalPrice) {
      setLowBalance(true);
    } else {
      setLowBalance(false);
    }

    console.log(totalPrice);

    setTotalAmount(totalPrice);
  }, [currency, chestDetails, chestsToBuy, userBalance]);

  useEffect(() => {
    const cachedChestDetails = Cookies.get("chestDetails");
    const cachedWalletBalance = Cookies.get("walletBalance");

    if (cachedChestDetails && cachedWalletBalance) {
      setChestDetails(JSON.parse(cachedChestDetails));
      setUserBalance(JSON.parse(cachedWalletBalance));
    } else {
      axios
        .get(`${apiUrl}/get_chest_details/`)
        .then((response) => {
          const chestData = response.data.data;
          setChestDetails(response.data.data);
          Cookies.set("chestDetails", JSON.stringify(chestData), {
            expires: 1 / 288,
          });
        })
        .catch((error) => {
          console.error("Error.");
        });

      const params_balance = {
        contract: "tokens",
        table: "balances",
        query: {
          account: user,
        },
        limit: 1000,
        offset: 0,
        indexes: [],
      };

      const j = {
        jsonrpc: "2.0",
        id: 1,
        method: "find",
        params: params_balance,
      };

      // const walletBalance = {
      //   "SWAP.HIVE": 0,
      //   DRIP: 0,
      //   LOOT: 0,
      // };
      axios
        .post("https://api.hive-engine.com/rpc/contracts", j)
        .then((response) => {
          const result = response.data.result;

          const walletBalance = {};
          const symbols = ["SWAP.HIVE", "LOOT", "DRIP"];

          for (const symbol of symbols) {
            const foundElement = result.find(
              (element) => element.symbol === symbol
            );
            walletBalance[symbol] = foundElement ? foundElement.balance : 0;
          }
          setUserBalance(walletBalance);
          Cookies.set("walletBalance", JSON.stringify(walletBalance), {
            expires: 1 / 288,
          });
        });
    }
  }, [user, apiUrl, updateData]);

  const handleSubmitButton = () => {
    const numIsInteger = Number.isInteger(parseInt(chestsToBuy));
    if (0 >= chestsToBuy || chestsToBuy > 50 || !numIsInteger) {
      window.alert("You can only buy 1-100 chests.");
      setChestsToBuy(0);
      return;
    }

    const keychain = window.hive_keychain;
    const payload = {
      contractName: "tokens",
      contractAction: "transfer",
      contractPayload: {
        symbol: currency,
        to: "piratesagapack",
        quantity: `${totalAmount}`,
        memo: `${chestsToBuy}, ${userData.items.chests}`,
      },
    };

    keychain.requestCustomJson(
      user,
      "ssc-mainnet-hive",
      "Active",
      JSON.stringify(payload),
      `Buy ${chestsToBuy} chests`,
      (response) => {
        if (response.success) {
          // setLoadTransactionImage(true);
          setAwaitTransaction(true);
          const confirmTransaction = (interval) => {
            return new Promise((resolve) => {
              setTimeout(() => {
                axios
                  .post(`${apiUrl}/confirm_transaction_detail_chest/`, {
                    symbol: currency,
                    amount: chestsToBuy,
                    amountPaid: totalAmount,
                    userName: user,
                  })
                  .then((response) => {
                    resolve(response.data.result.success);
                  })
                  .catch((error) => {
                    resolve(false); // Resolve false on error to continue the loop
                  });
              }, interval);
            });
          };

          const intervals = [10000, 15000, 20000, 30000, 60000, 120000, 180000];

          const executeTransactions = async () => {
            for (let i = 0; i < intervals.length; i++) {
              const transactionResponse = await confirmTransaction(
                intervals[i]
              );
              if (transactionResponse) {
                setConfirmTransactionText(true);
                setUpdateData(!updateData);
                break;
              } // Break the loop if transactionResponse is true
            }
          };

          executeTransactions();
        }
      }
    );
  };

  const transactionConfirmed = () => {
    setAwaitTransaction(false);
    setConfirmTransactionText(false);
  };
  return (
    <div>
      <div>
        {confirmTransaction ? (
          <div className="confirm-transaction-overlay">
            <div className="confirm-transaction">
              <div>
                {confirmTransactionText ? (
                  <button
                    onClick={transactionConfirmed}
                    className="transaction-button"
                  >
                    x
                  </button>
                ) : (
                  ""
                )}
              </div>
              {confirmTransactionText ? (
                <h2>Transaction Confirmed!</h2>
              ) : (
                <p>Confirming Transaction...please wait!</p>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="drop-rate-container container-chests">
          <h3 className="drop-rate-heading">
            Item Drop Rates: Each chest contains 3 items.
          </h3>
          <div className="drop-rate-items">
            <p>1 Small Fish: 10%</p>
            <p>1 Medium Fish: 8%</p>
            <p>1 Large Fish: 6%</p>
            <p>5 Small Fish: 4%</p>
            <p>3 Medium Fish: 4%</p>
            <p>1 Rum: 7%</p>
            <p>1 Herbs: 7%</p>
            <p>1 Wood: 10%</p>
            <p>1 Iron: 7%</p>
            <p>3 Wood: 5%</p>
            <p>2 Iron: 4%</p>
            <p>1 Copper: 5%</p>
            <p>Gunpowder: 5%</p>
            <p>Spyglass: 5%</p>
            <p>Rope: 5%</p>
            <p>Map: 5%</p>
            <p>Giant stash of Loot coins (600-2000): 2%</p>
            <p>Legendary Treasure Chest: 1%</p>
          </div>
          <ul className="legendary-loot">
            <li>Loot coins: (1000-3000)</li>
            <li>Wood: 5</li>
            <li>Iron: 3</li>
            <li>Copper: 1</li>
            <li>Small Fish: 5</li>
            <li>Medium Fish: 3</li>
            <li>Large Fish: 2</li>
            <li>Rum: 1</li>
            <li>Herbs: 1</li>
            <li>Gunpowder: 1</li>
            <li>Spyglass: 1</li>
            <li>Rope: 1</li>
            <li>Map: 1</li>
          </ul>
        </div>
      </div>
      <div className="shop-container buy-packs-container">
        <img
          className="chest-image"
          src="/CardImages/others/chests.png"
          alt="chest"
        />
        <div className="buy-packs-container">
          <h2>Buy Chests!</h2>
          <select onChange={handleCurrencyChange}>
            <option value="SWAP.HIVE">SWAP.HIVE</option>
            <option value="LOOT">LOOT</option>
          </select>
          <h3>
            Amount to Pay:{" "}
            {currency === "SWAP.HIVE"
              ? (chestDetails * chestsToBuy).toFixed(2)
              : 1000 * chestsToBuy}{" "}
            {currency}
          </h3>
          <input
            className="input-text"
            type="number"
            min="1"
            max="50"
            onChange={handleChestsChange}
            value={chestsToBuy}
          />
          {lowBalance ? (
            <div>
              Your {currency} balance is low {userBalance[currency]}. Select
              another token to pay with.
            </div>
          ) : (
            <button onClick={handleSubmitButton}>Submit</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyChests;
